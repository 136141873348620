import { marked } from 'marked'
import {React, useState, useEffect } from 'react'
import {useParams} from 'react-router-dom'
import './Article.css'

const axios = require('axios')

const Article = () => {
    let [article, setArticle] = useState("")
    const {title} = useParams()
    // need this hook to prevent app state change-render loop
    useEffect(() =>
        axios.get('/api/article?title=' + title + '/' + title + '.md')
            .then(res => {
                setArticle(res.data)
            })
            .catch(err => {
                console.error("Failed to get article" + err)
            }), [])
    
    marked.setOptions({
        breaks: true,
        gfm: true
    })
    let markdown = marked(article)
    return (
        <div id='article' className='margins title-secondary-text fit-images'>
            <p dangerouslySetInnerHTML={{__html: markdown}}></p>
        </div>
    )
}

export default Article