import React from 'react'
import './TitleBanner.css'
import logoText from '../../resources/logos/logo-title.png'

/* 
    TitleBanner represents the logo & background branding present on every page
*/

const TitleBanner = () => {
    return (
        <div className="banner-display">
            <div className="banner">
                <div className="background-blocks">
                    <div className="background-block red-background"></div>
                    <div className="background-block orange-background"></div>
                    <div className="background-block green-background"></div>
                    <div className="background-block yellow-background"></div>
                    <div className="background-block blue-background"></div>
                </div>
                <div className="vignette"></div>
                <img src={logoText} alt="Logo Text" className="logo logo-m" />
            </div>
        </div>
    )
}

export default TitleBanner