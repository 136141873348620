import React from 'react'
import PhotoBoard from '../../components/PhotoBoard/PhotoBoard'
import background from '../../resources/pictures-background.png'

/* This page will require an external database to query for images fitting criteria */

const Pictures = () => {
    return (
        <div className="contain-all">
            <img src={background} alt="Pictures Background" className="image-fit background" />
            <PhotoBoard maxAmount={8} src={"http://midnight-sink.com/api/pictures"} />
        </div>
    )
}

export default Pictures
