import React from 'react'

const LinkButton = ({text, destination}) => {
    return (
        <div>
            <a target="_blank" rel="noopener noreferrer" href={destination} className="button-padding title-primary-text white" >{text}</a>
        </div>
    )
}

export default LinkButton