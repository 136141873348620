import React from 'react'
import background from '../../resources/e30-background.JPG'
import PhotoBoard from '../../components/PhotoBoard/PhotoBoard'

/* This page will require an external server to serve images */

const E30 = () => {
    return (
        <div className="contain-all">
            <img src={background} alt="E30 Background" className="image-fit background" />
            <PhotoBoard maxAmount={8} src={"http://midnight-sink.com/api/E30"} />
        </div>
    )
}

export default E30
