import React from 'react'
import './NavBar.css'
import {
    BrowserRouter as Router,
    Routes, Route, Link
} from "react-router-dom"
import Home from '../../pages/Home/Home'
import Article from '../../pages/Article/Article'
import CodingProjects from '../../pages/CodingProjects/CodingProjects'
import Games from '../../pages/Games/Games'
import E30 from '../../pages/E30/E30'
import Pictures from '../../pages/Pictures/Pictures'

const NavBar = () => {
    return (
        <Router>
            <div className="nav-display">
                <div className="nav">
                    <div className="nav-blocks">
                        <Link className="nav-button red-background title-secondary-text" to="/">Home</Link>
                        <Link className="nav-button orange-background title-secondary-text" to="/CodingProjects">Coding Projects</Link>
                        <Link className="nav-button green-background title-secondary-text" to="/Games">Games</Link>
                        <Link className="nav-button yellow-background title-secondary-text" to="/E30">E30</Link>
                        <Link className="nav-button blue-background title-secondary-text" to="/Pictures">Pictures</Link>
                    </div>
                </div>
            </div>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/CodingProjects/:title" element={<Article />} />
                <Route path="/CodingProjects" element={<CodingProjects />} />
                <Route path="/Games" element={<Games />} />
                <Route path="/E30" element={<E30 />} />
                <Route path="/Pictures" element={<Pictures />} />
            </Routes>
        </Router>
    )
}

export default NavBar