import React from 'react'
import redPin from '../../resources/pin-red.png'
import redPinShadow from '../../resources/pin-red-shadow.png'
import yellowPin from '../../resources/pin-yellow.png'
import yellowPinShadow from '../../resources/pin-yellow-shadow.png'
import frame from '../../resources/photo-frame.png'
import frameShadow from '../../resources/photo-frame-shadow.png'

/// FIX: Somehow STILL overgowing the bounds of the screen and causing scrollbars to appear

// Frame constants
const MIN_FRAME_SIZE = 250
const MAX_FRAME_SIZE = 400
const FRAME_SHADOW_OFFSET_LEFT = 20
const FRAME_SHADOW_OFFSET_TOP = 7

// Pin constants
const PIN_HEIGHT = 100
const PIN_WIDTH = 30
const PIN_SHADOW_OFFSET_LEFT = 15
const PIN_SHADOW_HEIGHT = 100
const PIN_SHADOW_WIDTH = 40

// for performance, rate limit the resizing adjustment
const rateLimitResize = (func, msTime) => {
    let timer = null
    return () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            // clear the timer on completion
            timer = null
            // apply the function
            func.apply(this, [])
        }, msTime)
    }
}
// src is the source of the image, addition is the addional key added to the src to ensure images are considered unique, caption is the caption of the image
const PhotoFrame = ({src, addition}) => {
    // Add screen size to the state, so this component will reload when the screen resizes
    // this could be done much simpler if using the % unit worked in the below positioning code
    const [screenSize, setScreenSize] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    // Effect hook which allows for changing state in the function component (side effects ?)
    React.useEffect(() => {
        const RLR = rateLimitResize(function resize() {
            setScreenSize({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 200)
        // event listener for screen resizing
        window.addEventListener('resize', RLR)
        return () => window.removeEventListener('resize', RLR)
    })

    // pick random size
    let frameSize = MIN_FRAME_SIZE + Math.random()*(MAX_FRAME_SIZE - MIN_FRAME_SIZE)
    // nothing special about 0.87; it is just a size that allows the images to fit nicely
    let photoSize = frameSize * 0.87
    // pick positioning 
    // (The 1.25 ans 1.05 are "magic numbers" right now - the math is correct but the units of this style are different than the width/height attributes of the photo)
    let frameStyle = {
        position: 'absolute',
        top: 0.2*screenSize.height + Math.random()*0.77*(screenSize.height - (frameSize*1.25)), 
        left: Math.random()*(screenSize.width - frameSize*1.05)
    }
    // photo style, behind the frame in front of the shadow
    let photoStyle = {
        position: 'absolute',
        top: frameStyle.top + (frameSize-photoSize)/2.3,
        left: frameStyle.left + (frameSize-photoSize)/2.2,
        overflow: "hidden",
        objectFit: "cover"
    }
    // shadow presents slightly to the left of the photo
    let shadowStyle = {
        position: 'absolute',
        top: frameStyle.top + FRAME_SHADOW_OFFSET_TOP/1.5, 
        left: frameStyle.left - FRAME_SHADOW_OFFSET_LEFT
    }
    // pick a random pin
    let pin = Math.random() > 0.5 ? yellowPin : redPin
    let pinShadow = pin === yellowPin ? yellowPinShadow : redPinShadow
    // style the pin
    let pinStyle = {
        position: 'absolute',
        top: frameStyle.top - PIN_HEIGHT/1.5, 
        left: frameStyle.left + frameSize/2.2
    }
    // style the shadow of the pin
    let pinShadowStyle = {
        position: 'absolute',
        top: frameStyle.top - PIN_HEIGHT/1.5, 
        left: frameStyle.left + frameSize/2.2 - PIN_SHADOW_OFFSET_LEFT
    }
    return (
        <div>
            <img src={frameShadow} alt="Frame Shadow" width={frameSize} height={frameSize} style={shadowStyle} />
            <img src={src+"?"+addition} alt="Inside" width={photoSize} height={photoSize} style={photoStyle} />
            <img src={frame} alt="Frame" width={frameSize} height={frameSize} style={frameStyle} />
            <img src={pinShadow} alt="Pin" width={PIN_SHADOW_WIDTH} height={PIN_SHADOW_HEIGHT} style={pinShadowStyle} />
            <img src={pin} alt="Pin" width={PIN_WIDTH} height={PIN_HEIGHT} style={pinStyle} />
        </div>
    )
}

export default PhotoFrame