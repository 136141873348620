import React from 'react'
import './Home.css'
import LinkButton from '../../components/LinkButton/LinkButton'
import background from '../../resources/home-background.jpg'

/* the home screen is the defualt page of the app */
/* the home screen is where the user will be redirected if the land on an invalid page */

const Home = () => {
    return (
        <div className="body-content contain-all">
            <div className="home-prompt">
                <h1 className="white title-primary-text">Welcome to <i>The Midnight Sink</i></h1>
                <h2 className="white title-secondary-text">Browse my GitHub or stalk me on LinkedIn...</h2>
                <div className="row-content">
                    <div className="github-button">
                        <LinkButton text="GitHub" destination="https://github.com/Midnight-Sink" />
                    </div>
                    <div className="linkedin-button">
                        <LinkButton text="LinkedIn" destination="https://www.linkedin.com/in/derek-lore-4aa824168/" />
                    </div>
                </div>
            </div>
            <img src={background} alt="Homepage Background" className="image-fit" />
        </div>
    )
}

export default Home