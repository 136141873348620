import { React, useState, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Routes, Route, Link
} from "react-router-dom"
import './CodingProjects.css'
import LogoMain from '../../resources/logos/logo-main.png'

const axios = require('axios')

const CodingProjects = () => {
    let [articles, setArticles] = useState([])

    // need this hook to prevent app state change-render loop
    useEffect(() =>
        axios.get('/api/articles')
            .then(res => {
                setArticles(res.data)
            })
            .catch(err => {
                console.error('Failed to get articles' + err)
            }), [])

    // The article tiles in the grid
    let articleTiles = []
    let i = 0
    articles.forEach((article) => {
        articleTiles.push(
            <Link key={i} to={"/CodingProjects/" + article} className="tile tile-color large-button-background">
                <img className="thumb"
                    src={'http://midnight-sink.com/api/article?title='+article+'/'+article+'.png'}
                    alt="Default Thumb"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = LogoMain
                    }}
                />
                <p className="title-secondary-text">{article}</p>
            </Link>
        )
        i++
    })

    return (
        <div className="plain-background">
            <p className="title-primary-text page-title"> Coding Projects! </p>
            <div className="article-grid">
                {articleTiles}
            </div>
        </div>
    )
}

export default CodingProjects
