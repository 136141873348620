import React from 'react'
import PhotoFrame from '../PhotoFrame/PhotoFrame'

const PhotoBoard = ({maxAmount, src}) => {
    const [reload, setReload] = React.useState({reload: false})
    let photos = []
    let numPhotos = 1 + Math.random() * (maxAmount - 1)
    for (let i = 0; i < numPhotos; i++) {
        photos.push(<PhotoFrame src={src} key={i} addition={Date.now()+i} />)
    }

    return (
        <div>
            {photos} 
            <button onClick={() => setReload(!reload)} className="large-button-background blue-background title-primary-text white bottom-right">
                Refresh
            </button>
        </div>
    )
}

export default PhotoBoard