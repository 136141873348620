import React from 'react'

const Games = () => {
    return (
        <div className="plain-background contain-all">
            <p className="title-primary-text page-title placeholder-text"> There's nothing here yet... </p>
        </div>
    )
}

export default Games